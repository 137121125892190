<template>
  <div id="app">
    <div v-if="viewReport">
      <table class="stats">
        <tr v-for="(percent, type) in stats" :key="type">
          <td>{{ percent }}%</td>
          <td>{{ descriptions[type] }}</td>
        </tr>
      </table>
      <div class="controls">
        <button class="control" @click="viewReport = false">↩️</button>
      </div>
    </div>
    <div v-else>
      <div
        class="question"
        v-for="(question, index) in flow[flowIndex].questions"
        :key="flowIndex + ':' + index"
      >
        <input
          class="question-input"
          type="checkbox"
          :id="index"
          :name="index"
          v-model="flow[flowIndex].answer"
          :value="index"
        />
        <label class="question-label" :for="index">{{ question.text }}</label>
      </div>
      <div class="controls">
        <button
          class="control"
          :style="canGoToPrevious ? '' : 'opacity: 0'"
          @click="goToPrevious"
        >
          ⬅️
        </button>
        <button class="control" @click="report" :style="canViewReport ? '' : 'opacity: 0'">✅</button>
        <button
          class="control"
          :style="canGoToNext ? '' : 'opacity: 0'"
          @click="goToNext"
        >
          ➡️
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      questions: [
        {
          text: [
            "Я социально непригоден и нежелателен в ситуациях работы или общения.",
            "Другие люди потенциально критически настроены, безразличны, склонны унижать или отвергать меня.",
            "Я не могу выносить неприятные чувства.",
            "Если люди сблизятся со мной, они обнаружат, кто я такой, и отвергнут меня.",
            "Невыносимо, когда меня считают низшим или неадекватным.",
            "Я должен любой ценой избегать неприятных ситуаций.",
            "Если я чувствую что-то неприятное или думаю об этом, я должен попытаться забыть об этом или отвлечься, например, подумать о чем-нибудь еще, выпить, принять таблетку или посмотреть телевизор.",
            "Я должен избегать ситуаций, в которых я привлекаю внимание, или быть как можно более незаметным.",
            "Неприятные чувства усилятся и выйдут из-под контроля.",
            "Если другие критикуют меня, они, видимо, правы.",
            "Лучше не делать ничего, чем пытаться делать то, что может закончиться неудачей.",
            "Если я не думаю о проблеме, мне не нужно ее решать.",
            "Любые признаки напряженности в отношениях указывают на то, что отношения испортились; следовательно, их нужно разорвать.",
            "Если я буду игнорировать проблему, она исчезнет.",
          ],
          color: "yellow",
          type: "AvPD",
          description: "Избегающее расстройство личности",
        },
        {
          text: [
            "Я нуждающийся и слабый.",
            "Я нуждаюсь в ком-то, кто всегда доступен, чтобы помочь мне справиться с тем, что я должен сделать, или если случится что-то плохое.",
            "Мой помощник может опекать, поддерживать меня и доверять мне — если захочет.",
            "Я беспомощен, когда действую самостоятельно.",
            "Если мне не удается привязаться к более сильному человеку, я остаюсь в полном одиночестве.",
            "Самое плохое, что может со мной случиться, — это если меня бросят.",
            "Если меня не полюбят, я всегда буду несчастен.",
            "Я не должен делать ничего, что может обидеть того, кто поддерживает меня или помогает мне.",
            "Я должен находиться в зависимом положении, чтобы поддерживать его хорошее отношение.",
            "Я должен всегда иметь доступ к нему.",
            "Я должен поддерживать как можно более близкие отношения.",
            "Я не могу сам принимать решения.",
            "Я не могу справляться с проблемами, как это делают другие.",
            "Мне нужны другие люди, чтобы помогать мне принимать решения или говорить мне, что делать.",
          ],
          color: "green",
          type: "DPD",
          description: "Зависимое расстройство личности",
        },
        {
          text: [
            "Я самодостаточен, но я нуждаюсь в других, чтобы они помогли мне в достижении моих целей.",
            "Единственный способ сохранить чувство собственного достоинства — косвенно утверждать себя, например не выполнять инструкции.",
            "Я люблю привязываться к людям, но я не хочу, чтобы при этом мной управляли.",
            "Люди, наделенные властью, обычно навязчивы, требовательны, назойливы и склонны командовать.",
            "Я должен сопротивляться доминированию властей, но в то же время добиваться от них одобрения и принятия.",
            "Невыносимо находиться под контролем или во власти других.",
            "Я должен все делать по-своему.",
            "Установление крайних сроков, исполнение требований и приспособление — прямая угроза моей гордости и самодостаточности.",
            "Если я подчиняюсь правилам, как того ожидают люди, это ограничивает мою свободу действий.",
            "Лучше не выражать свой гнев прямо, но показывать неудовольствие путем неподчинения.",
            "Я сам знаю, что мне нужно и что для меня хорошо, и окружающие не должны указывать мне, что делать.",
            "Правила произвольны и ограничивают меня.",
            "Другие люди часто слишком требовательны.",
            "Если я считаю людей слишком властными, я имею право игнорировать их требования.",
          ],
          color: "cyan",
          type: "PAPD",
          description: "Пассивно-агрессивное расстройство личности",
        },
        {
          text: [
            "Я полностью ответствен за себя и других.",
            "Я должен полагаться на себя, чтобы убедиться, что все сделано.",
            "Другие слишком легкомысленны, часто безответственны, снисходительны к себе или некомпетентны.",
            "Важно любую работу выполнять идеально.",
            "Мне нужен порядок, системы и правила, чтобы должным образом выполнить работу.",
            "Если у меня нет систем, все может развалиться.",
            "Любой недостаток или дефект в работе может привести к катастрофе.",
            "Необходимо всегда придерживаться самых высоких стандартов, или все развалится.",
            "Я должен полностью контролировать свои эмоции.",
            "Люди должны все делать по-моему.",
            "Если я не выполняю работу на самом высоком уровне, я потерплю неудачу.",
            "Недостатки, дефекты или ошибки недопустимы.",
            "Детали чрезвычайно важны.",
            "Я делаю все наилучшим образом.",
          ],
          color: "violet",
          type: "OCD",
          description: "Обсессивно-компульсивное расстройство личности",
        },
        {
          text: [
            "Я должен остерегаться.",
            "Сила или хитрость — лучший способ добиться своего.",
            "Мы живем в джунглях, и выживает сильнейший.",
            "Люди доберутся до меня, если я первым не доберусь до них.",
            "Не обязательно сдерживать обещания и отдавать долги.",
            "Можно лгать, если тебя не уличат во лжи.",
            "Со мной обращались несправедливо, и я имею право получить причитающуюся мне долю любыми доступными средствами.",
            "Другие люди слабы и заслуживают того, чтобы быть обманутыми.",
            "Если я не притесняю других, они будут притеснять меня.",
            "Я должен делать все, что останется безнаказанным.",
            "Не имеет значения, что другие думают обо мне.",
            "Если я чего-то хочу, я должен делать все, чтобы это получить.",
            "Я могу выйти сухим из воды, поэтому я не должен беспокоиться о плохих последствиях.",
            "Если люди не могут позаботиться о себе, это их проблема.",
          ],
          color: "blue",
          type: "ASPD",
          description: "Антисоциальное расстройство личности",
        },
        {
          text: [
            "Я особенный человек.",
            "Так как я лучше всех, я имею право на особое обращение и привилегии.",
            "Я не должен быть связан правилами, которые применимы к другим людям.",
            "Очень важно получить признание, заслужить похвалу и вызвать восторг.",
            "Если другие не уважают мой статус, они должны быть наказаны.",
            "Другие люди должны удовлетворять мои потребности.",
            "Другие люди должны понимать, насколько я особенный.",
            "Невыносимо, если ко мне не проявляют должного уважения или я не получаю то, на что имею право.",
            "Другие люди не заслуживают восхищения или богатства, которые они получают.",
            "Люди не имеют права критиковать меня.",
            "Ничьи потребности не должны сталкиваться с моими собственными.",
            "Так как я настолько талантлив, люди должны продвигать мою карьеру.",
            "Меня понимают лишь такие же выдающиеся личности, как я.",
            "Я имею все основания надеяться на большое будущее.",
          ],
          color: "red",
          type: "NPD",
          description: "Нарциссическое расстройство личности",
        },
        {
          text: [
            "Я интересный, привлекательный человек.",
            "Чтобы чувствовать себя счастливым, я должен привлекать внимание других.",
            "Если я не развлекаю или не впечатляю людей, я ничтожество.",
            "Если я неинтересен другим, они не будут любить меня.",
            "Чтобы добиться своего, нужно изумлять или развлекать людей.",
            "Если люди не реагируют на меня позитивно, они отвратительны.",
            "Ужасно, если люди игнорируют меня.",
            "Я должен быть в центре внимания.",
            "Я могу не утруждать себя обдумыванием — мне достаточно положиться на «внутреннее» чувство.",
            "Когда я развлекаю людей, они не замечают моих слабостей.",
            "Я не терплю скуку.",
            "Если я испытываю желание что-то делать, я должен это делать.",
            "Люди обратят на меня внимание, только если я действую крайними способами.",
            "Чувства и интуиция намного важнее рационального мышления и планирования.",
          ],
          color: "cadetblue",
          type: "HPD",
          description: "Гистрионное расстройство личности",
        },
        {
          text: [
            "Не имеет значения, что другие люди думают обо мне.",
            "Для меня важно быть свободным и независимым.",
            "Мне больше нравится действовать в одиночку, чем с кем-нибудь.",
            "Во многих ситуациях лучше остаться одному.",
            "Никто не влияет на мои решения.",
            "Близкие отношения с людьми не важны для меня.",
            "Я устанавливаю для себя собственные стандарты и ставлю перед собой свои цели.",
            "Моя частная жизнь намного более важна для меня, чем близость к людям.",
            "Для меня не имеет значения, что думают другие люди.",
            "Я могу справляться со всем самостоятельно без чьей-либо помощи.",
            "Лучше быть одному, чем чувствовать себя «привязанным» к другими людям.",
            "Я не должен доверять никому.",
            "Я могу использовать людей в своих собственных целях, пока мне удается избежать близких отношений с ними.",
            "Отношения беспорядочны и ограничивают свободу.",
          ],
          color: "chocolate",
          type: "SzPD",
          description: "Шизоидное и шизотипическое расстройства личности",
        },
        {
          text: [
            "Я не могу доверять людям.",
            "Другие люди имеют скрытые мотивы.",
            "Другие будут пытаться использовать меня или манипулировать мной, если я не буду проявлять осторожность.",
            "Я должен всегда быть начеку.",
            "Доверять людям небезопасно.",
            "Если люди ведут себя дружелюбно, они могут попытаться использовать или эксплуатировать меня.",
            "Люди воспользуются мною, если я предоставлю им такую возможность.",
            "Другие люди недружелюбны.",
            "Другие люди будут сознательно пытаться унизить меня.",
            "Часто люди преднамеренно хотят досадить мне.",
            "У меня возникнут серьезные проблемы, если я позволю другим людям думать, что они могут безнаказанно плохо обращаться со мной.",
            "Если другие люди что-то узнают обо мне, они используют это против меня.",
            "Люди часто говорят одно, а думают другое.",
            "Человек, с которым я нахожусь в близких отношениях, может оказаться вероломным или предать меня.",
          ],
          color: "grey",
          type: "PPD",
          description: "Параноидное расстройство личности",
        },
      ],
      flow: [],
      descriptions: {},
      flowIndex: 0,
      viewReport: false,
      canViewReport: false,
    };
  },
  created() {
    for (let index = 0; index < this.questions[0].text.length; index++) {
      const questions = this.questions.map((q) => {
        this.descriptions[q.type] = q.description;
        return {
          text: q.text[index],
          type: q.type,
        };
      });

      function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
      }

      shuffleArray(questions);

      this.flow.push({
        questions,
        answer: [],
      });
    }
  },
  computed: {
    canGoToPrevious() {
      return this.flowIndex > 0;
    },
    canGoToNext() {
      return this.flowIndex < this.flow.length - 1;
    },
    answered() {
      const res = [];
      this.flow.forEach((f) => {
        f.answer.forEach((a) => {
          res.push(f.questions[a]);
        });
      });
      return res;
    },
    stats() {
      const counts = {};
      this.answered.forEach((question) => {
        if (!counts.hasOwnProperty(question.type)) {
          counts[question.type] = 0;
        }
        counts[question.type]++;
      });
      const countAll = this.answered.length;
      for (let type in counts) {
        counts[type] = Math.round((counts[type] / countAll) * 100);
      }
      const sortableCounts = Object.entries(counts)
        .sort(([, a], [, b]) => b - a)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      return sortableCounts;
    },
  },
  methods: {
    goToPrevious() {
      if (this.canGoToPrevious) {
        this.flowIndex -= 1;
        this.scrollToTop();
      }
    },
    goToNext() {
      if (this.canGoToNext) {
        this.flowIndex += 1;
        this.scrollToTop();
        if (this.flowIndex >= this.flow.length - 1) {
          this.canViewReport = true;
        }
      }
    },
    report() {
      if (this.answered.length < 3) {
        return alert("Для корректного результата требуется минимум 3 ответа");
      }
      this.viewReport = true;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.controls {
  display: flex;
  position: sticky;
  bottom: 1em;
}
.control {
  margin: auto;
  background-color: white;
  padding: 1em;
  border-radius: 1em;
}
.question {
  display: flex;
  margin: 1em;
}
.question-label {
  margin: 1em;
  flex-basis: calc(100% - 1em);
}
.question-input {
  margin: auto;
}
.stats {
  border-collapse: separate;
  border-spacing: 1em;
}
</style>
